import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import ChartTableHeader from './ChartTableHeader';
import { getOrdersSalesRequest } from '../../store/actions/dashboard';
import Loader from '../Loader';

const chartConfig = {
  series: [
    {
      name: 'Sales',
      data: [],
    },
    {
      name: 'Quantity',
      data: [],
    },
  ],
  options: {
    chart: {
      type: 'line',
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'right',
      fontSize: '16px',
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    colors: ['#EF5DA8', '#FF5F00'],
    markers: { size: 4, colors: '#15274B', strokeColor: '#15274B' },
    stroke: { width: 1 },
    xaxis: { labels: {} },
    yaxis: { labels: { style: { colors: ['#15274B'], fontSize: '14px', fontWeight: 600 } }, tickAmount: 10 },
  },
};

function SalesChart() {
  const [chartLoading, setChartsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const ordersSales = useSelector((state) => state.dashboard.ordersSales);
  const qtyData = ordersSales.map((d) => +d.qty);
  const amountData = ordersSales.map((d) => +d.amount);
  const startDate = ordersSales.map((d) => (moment(d?.startDate).format('DD-MM-YY')));
  chartConfig.series[0].data = qtyData;
  chartConfig.series[1].data = amountData;
  chartConfig.options.xaxis.categories = startDate;

  useEffect(() => {
    (async () => {
      setChartsLoading(true);
      await dispatch(getOrdersSalesRequest({
        startDate: moment().add(-1, 'month').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      }));
      setChartsLoading(false);
    })();
  }, []);

  const sortData = async (reqData) => {
    setLoading(true);
    await dispatch(getOrdersSalesRequest(reqData));
    setLoading(false);
  };

  return (
    <div className="salesChart">
      <div className="header">
        <ChartTableHeader title="Sales Report" changeValue={sortData} />
      </div>
      {!loading ? (
        <div style={{ marginLeft: -15 }}>
          {!chartLoading
            ? (
              <Chart
                options={chartConfig.options}
                series={chartConfig.series}
                width="100%"
                height="400"
              />
            )
            : (
              <div>
                <Loader style={{ height: 200 }} />
              </div>
            )}
        </div>
      ) : <Loader style={{ height: 200 }} />}
    </div>
  );
}

export default SalesChart;
