import React from 'react';
import { ReactComponent as BasketSvg } from './assets/icons/basket.svg';
import { ReactComponent as DashboardSvg } from './assets/icons/dashboard.svg';
import { ReactComponent as ProductSvg } from './assets/icons/drug.svg';
import { ReactComponent as PatientsSvg } from './assets/icons/patients.svg';

const menus = [
  {
    menu: 'Dashboard',
    path: '/dashboard',
    icon: <DashboardSvg />,
  },
  {
    menu: 'Orders',
    path: '/orders',
    icon: <BasketSvg />,
  },
  // {
  //   menu: 'Settings',
  //   path: '/settings',
  //   icon: <SettingsSvg />,
  // },
  {
    menu: 'Members',
    path: '/patients',
    icon: <PatientsSvg />,
  },
  {
    menu: 'Products',
    path: '/products',
    icon: <ProductSvg />,
  },
];

export default menus;
