import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getNotificationsRequest } from "../store/actions/notifications";
import _ from 'lodash'
import Loader from "./Loader";
import { ReactComponent as DeleteSvg } from "../assets/icons/remove.svg";
import PropTypes from "prop-types";
import SeeMore from "./SeeMore";

function Notifications(props) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1)
  const notifications = useSelector(state => state.notifications.notifications)
  const notificationsStatus = useSelector(state => state.notifications.notificationsStatus)
  const notificationsTotalPages = useSelector(state => state.notifications.notificationsTotalPages)

  useEffect(() => {
    dispatch(getNotificationsRequest({ page, order: 'desc', orderBy: 'createdAt' }))
  }, [page])

  console.log(notifications)

  return (
    <div className="notifications">
      <div className="top">
        <p>Notifications</p>
        <DeleteSvg onClick={() => props.onClose()} />
      </div>
      {notifications.map(n => (
        <div className="notificationItem">
          {n.status === 'unseen' ? <span className="dot" /> : null}
          <div className="info">
            <p className="content"><b>Ann hghj </b>fds</p>
            <p className="date">dsfd </p>
          </div>
        </div>
      ))}
      {+notificationsTotalPages > 1 && page !== +notificationsTotalPages ? <SeeMore onClick={() => setPage(page + 1)} /> : null}
      {notificationsStatus === 'ok' && _.isEmpty(notifications) ? <div className="noNotifications">No notifications</div> : null}
      {notificationsStatus === 'request' && _.isEmpty(notifications) ? <Loader /> : null}
    </div>
  );
}

Notifications.propTypes = {
  onClose: PropTypes.func.isRequired
}
export default Notifications;
