import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { getOrdersRequest } from '../store/actions/orders';
import Loader from './Loader';

const getColor = (status) => ({
  delivered: '#0B8B00',
  pending: '#FF5F00',
  canceled: '#EB001B',
})[status];

function OrdersTable() {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const orders = useSelector((state) => state.orders.orders);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getOrdersRequest({ page: 1 }));
      setLoading(false);
    })();
  }, []);
  return (
    <div className="table ordersTable">
      {loading ? <Loader /> : null}
      {!loading && _.isEmpty(orders) ? <div className="noData">No data</div> : null}
      {!loading && !_.isEmpty(orders) ? (
        <div className="table">
          <div className="head">
            <div className="number">Number</div>
            <div className="patient">Member</div>
            <div className="date">Date</div>
            <div className="productName">Product Name</div>
            <div className="qty">Quantity</div>
            <div className="price">Price</div>
            <div className="status">Status</div>
          </div>
          <div className="body">
            {orders.map((order) => (
              <div className="row" key={order.id}>
                <div className="number">
                  {_.get(order, 'items[0].itemNumber', '-')}
                </div>
                <div className="patient">
                  {order.customerName}
                </div>
                <div className="date">
                  {moment(order.createdAt).format('MM-DD-YYYY')}
                </div>
                <div className="productName">
                  {order.items.map((o) => o.productName).join(', ')}
                </div>
                <div className="qty">
                  {_.sumBy(order.items, (o) => +o.qty)}
                </div>
                <div className="price">
                  {`$${(_.sumBy(order.items, (o) => +o.price) || 0).toFixed()}`}
                </div>
                <div className="status" style={{ color: getColor(order.status) }}>
                  {_.upperFirst(order.status) || '-'}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default OrdersTable;
