import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as NotificationSvg } from '../assets/icons/notification.svg';
import IconButton from './form/IconButton';
import SettingsDropdown from './SettingsDropdown';
import Switcher from './form/Switcher';
import Notifications from "./Notifications";
import { getUnreadNotificationsCountRequest, markNotificationsAsReadRequest } from "../store/actions/notifications";
import { useDispatch, useSelector } from "react-redux";

function NavBar() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [showNotifications, setShowNotifications] = useState(false)
  const notificationsCount = useSelector(state => state.notifications.notificationsCount)

  useEffect(() => {
    dispatch(getUnreadNotificationsCountRequest())
  }, [])

  return (
    <div id="navBar">
      {pathname === '/dashboard' ? <Switcher label="FSA" /> : null}
      <IconButton
        icon={<NotificationSvg />}
        onClick={() => {
          setShowNotifications(!showNotifications)
          dispatch(markNotificationsAsReadRequest())
        }}
        count={+notificationsCount}
      />
      <div className="notificationsContainer">
        {showNotifications ? <Notifications onClose={() => setShowNotifications(false)} /> : null}
      </div>
      <SettingsDropdown />
    </div>
  );
}

export default NavBar;
