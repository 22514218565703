import { io } from 'socket.io-client';
import Account from '../../helpers/Account';

const { REACT_APP_API_URL } = process.env;

export const GET_NOTIFICATION = 'GET_NOTIFICATION';

let socket;

export function init() {
  return (dispatch) => {
    if (socket) {
      return;
    }
    const token = Account.getToken();

    socket = io.connect(REACT_APP_API_URL, {
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });

    socket.on('newOrder', (data) => {
      dispatch({
        type: GET_NOTIFICATION,
        payload: data,
      });
    })
  };
}
