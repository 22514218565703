import React from 'react';
import Chart from 'react-apexcharts';
import ChartTableHeader from './ChartTableHeader';
import Loader from '../Loader';
import Tooltip from '../ToolTip';

function RadialBarChart({
                          count, percentage, name, chartLoading, sortData,
                        }) {
  const options = {
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        shadeIntensity: 0.15,
        gradientToColors: [name !== 'Patients' && name !== 'Today Sales' ? '#FF8338' : '#2877EE'],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, -100],
      },
    },
    stroke: {
      dashArray: 4,
      color: name !== 'Patients' && name !== 'Today Sales' ? '#FF8338' : '#2877EE',
    },
    plotOptions: {
      radialBar: {
        inverseOrder: false,
        startAngle: 0,
        endAngle: 360,
        offsetX: 0,
        offsetY: 0,
        hollow: {
          margin: 5,
          size: '50%',
          background: 'transparent',
          image: undefined,
          imageWidth: 150,
          imageHeight: 150,
          imageOffsetX: 0,
          imageOffsetY: 0,
          imageClipped: true,
          position: 'front',
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5,
          },
        },
        track: {
          show: true,
          startAngle: undefined,
          endAngle: undefined,
          background: '#f2f2f2',
          strokeWidth: '97%',
          opacity: 1,
          margin: 5,
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5,
          },
        },
        dataLabels: {
          show: true,
          name: {
            show: true,
            fontSize: '16px',
            fontFamily: undefined,
            fontWeight: 600,
            color: undefined,
            offsetY: -10,
          },
          value: {
            show: true,
            fontSize: '24px',
            fontFamily: undefined,
            fontWeight: 400,
            color: name !== 'Patients' && name !== 'Today Sales' ? '#FF8338' : '#2877EE',
            offsetY: 16,
            formatter(val) {
              return `${val}%`;
            },
          },
          total: {
            show: true,
            label: name,
            color: name !== 'Patients' && name !== 'Today Sales' ? '#FF8338' : '#2877EE',
            fontSize: '15px',
            fontFamily: undefined,
            fontWeight: 600,
            formatter() {
              return `${name !== 'Patients' ? '$' : ''} ${count}`;
            },
          },
        },
      },
    },
  };

  return (
    <>
      <div className="tableHeader">
        <ChartTableHeader changeValue={sortData} />
      </div>
      <Tooltip text={(
        <span>
          {percentage}
          {name !== 'Patients' ? '%' : null}
        </span>
      )}
      >
        <div className="chartRound">
          {!chartLoading
            ? (
              <Chart
                type="radialBar"
                key={count}
                series={[percentage]}
                options={{ ...options }}
                width="220px"
                height="220px"
              />
            )
            : (
              <div>
                <Loader style={{ height: 200 }} />
              </div>
            )}
        </div>
      </Tooltip>

    </>
  );
}

export default RadialBarChart;
