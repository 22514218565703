import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import ChartTableHeader from './ChartTableHeader';
import BarChartRow from './BarChartRow';
import Loader from '../Loader';
import { getOrdersCategoriesRequest } from '../../store/actions/dashboard';

function TopCategories() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { payload } = await dispatch(getOrdersCategoriesRequest({
        startDate: moment().add(-1, 'month').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      }));
      setData(payload.data.categories);
      setLoading(false);
    })();
  }, []);

  const sortData = async (reqData) => {
    setLoading(true);
    const { payload } = await dispatch(getOrdersCategoriesRequest(reqData));
    setData(payload.data?.categories);
    setLoading(false);
  };

  return (
    <div className="topCompaniesBySales">
      <ChartTableHeader changeValue={sortData} title="Top companies by sales" />
      {
        !loading ? data?.map((i, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <BarChartRow key={index} percentage={i.percentage?.slice(0, -1)} title={i.name} />
        )) : <Loader style={{ height: 200 }} />
      }
      {_.isEmpty(data) && !loading ? (<p className="no_data_text">No data to display...</p>) : null}
    </div>
  );
}
export default TopCategories;
