import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import menus from '../menus';
import logo from '../assets/images/logo.png';

function Menu(props) {
  if (!props.isShowing) {
    return null;
  }
  return (
    <div id="menu">
      <div className="logoContainer">
        <div className="companyName">HP Logo</div>
        <span className="border"/>
        <img src={logo} alt="" />
      </div>
      {menus.map((menu) => (
        <NavLink to={menu.path} className="menuItem" key={menu.menu}>
          {menu.icon}
          <p>{menu.menu}</p>
        </NavLink>
      ))}
    </div>
  );
}

Menu.propTypes = {
  isShowing: PropTypes.bool.isRequired,
};

export default Menu;
