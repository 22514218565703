import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import RadialBarChart from './RadialBarChart';
import {
  getOPaymentsStatisticFundsRequest, getOPaymentsStatisticFundsUsedRequest,
  getOrdersStatisticSalesRequest,
  getUsersStatisticPatientsRequest,
} from '../../store/actions/dashboard';

function RadialBarChartContainer() {
  const dispatch = useDispatch();
  const statisticPatients = useSelector((state) => state.dashboard.statisticPatients);
  const statisticSales = useSelector((state) => state.dashboard.statisticSales);
  const statisticPaymentAllocated = useSelector((state) => state.dashboard.statisticPaymentAllocated);
  const statisticPaymentUsed = useSelector((state) => state.dashboard.statisticPaymentUsed);
  const [chartLoading, setChartsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setChartsLoading(true);
      await dispatch(getUsersStatisticPatientsRequest({
        startDate: moment().add(-1, 'month').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      }));

      await dispatch(getOrdersStatisticSalesRequest({
        startDate: moment().add(-1, 'month').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      }));

      await dispatch(getOPaymentsStatisticFundsRequest({
        startDate: moment().add(-1, 'month').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        type: 'allocated',
      }));

      await dispatch(getOPaymentsStatisticFundsUsedRequest({
        startDate: moment().add(-1, 'month').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        type: 'used',
      }));
      setChartsLoading(false);
    })();
  }, []);

  const sortDataPatients = useCallback((reqData) => {
    dispatch(getUsersStatisticPatientsRequest(reqData));
  }, [statisticPatients]);

  const sortDataFunds = useCallback((reqData) => {
    dispatch(getOPaymentsStatisticFundsRequest(reqData));
  }, [statisticPaymentAllocated]);

  const sortDataSales = useCallback((reqData) => {
    dispatch(getOrdersStatisticSalesRequest(reqData));
  }, [statisticSales]);

  const sortDataUsed = useCallback((reqData) => {
    dispatch(getOPaymentsStatisticFundsUsedRequest(reqData));
  }, [statisticPaymentUsed]);

  return (
    <div className="shortRound">
      <div>
        <RadialBarChart
          sortData={sortDataPatients}
          chartLoading={chartLoading}
          percentage={statisticPatients.percentage}
          count={statisticPatients.count}
          name="Patients"
        />

        <RadialBarChart
          sortData={sortDataFunds}
          chartLoading={chartLoading}
          percentage={statisticPaymentAllocated.amount}
          count={statisticPaymentAllocated.percentage}
          name="Allocated Funds"
        />
      </div>

      <div>
        <RadialBarChart
          sortData={sortDataSales}
          chartLoading={chartLoading}
          percentage={statisticSales.percentage}
          count={statisticSales.sales}
          name="Today Sales"
        />

        <RadialBarChart
          sortData={sortDataUsed}
          chartLoading={chartLoading}
          percentage={statisticPaymentUsed.amount}
          count={statisticPaymentUsed.percentage}
          name="Used Funds"
        />
      </div>
    </div>
  );
}

export default RadialBarChartContainer;
