import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { getPatientsRequest } from '../store/actions/patients';
import Loader from './Loader';

function PatientsTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const patients = useSelector((state) => state.patients.patients);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getPatientsRequest());
      setLoading(false);
    })();
  }, []);

  return (
    <div className="patientsTable">
      {loading ? <Loader /> : null}
      {!loading && _.isEmpty(patients) ? <div className="noData">No data</div> : null}
      {!loading && !_.isEmpty(patients) ? (
        <div className="table">

          <div className="head">
            <div className="name">Name</div>
            <div className="date">Date of Birth</div>
            <div className="phone">Phone Number</div>
            <div className="address">Address</div>
          </div>
          <div className="body">
            {patients.map((patient) => (
              <div className="row" key={patient.id} onClick={() => navigate(`/patients/${patient.id}`)}>
                <div className="name">
                  {`${patient.fName} ${patient.lName}`}
                </div>
                <div className="date">
                  {patient.dob ? moment(patient.dob).format('MM-DD-YYYY') : '-'}
                </div>
                <div className="phone">
                  {patient.phone}
                </div>
                <div className="address">
                  {patient.address1}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default PatientsTable;
