import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ChartTableHeader from './ChartTableHeader';
import { getOrdersProductsHealthPlanRequest } from '../../store/actions/dashboard';
import Utils from '../../helpers/Utils';
import Loader from '../Loader';

function TopSoldProducts() {
  const [loading, setLoading] = useState(false);
  const ordersHealthPlan = useSelector((state) => state.dashboard.ordersHealthPlan);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrdersProductsHealthPlanRequest({
      startDate: moment().add(-1, 'month').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    }));
  }, []);

  const sortData = async (reqData) => {
    setLoading(true);
    await dispatch(getOrdersProductsHealthPlanRequest(reqData));
    setLoading(false);
  };
  return (
    <div className="topSoldProducts chartTableContainer">
      <ChartTableHeader title="Top Sold Products" changeValue={sortData} />
      <div>
        <table className="chartTable">
          <thead>
            <tr>
              <th className="item">Item</th>
              <th className="sales">Sales</th>
              <th className="amount">Amount</th>
            </tr>
          </thead>
          <tbody>
            {!loading ? ordersHealthPlan?.map((d) => (
              <tr key={d.productId}>
                <td className="item">{d.productName}</td>
                <td className="sales">{d.sales}</td>
                <td className="amount">{`$${Utils.formatNumber(d.amount)}`}</td>
              </tr>
            )) : <Loader style={{ height: 200 }} />}
          </tbody>
        </table>
      </div>
      <div className="seeAll">
        <p>See All</p>
      </div>
    </div>
  );
}

TopSoldProducts.propTypes = {
  data: PropTypes.array,
};
TopSoldProducts.defaultProps = {
  data: [],
};

export default TopSoldProducts;
