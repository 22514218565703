class Utils {
  static formatNumber(number) {
    if (!number) {
      return ' ';
    }
    return (`${(+number || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.00', '')}`).replace('$-', '-$');
  }
}

export default Utils;
