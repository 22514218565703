import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Wrapper from '../components/Wrapper';
import Input from '../components/form/Input';
import Select from '../components/form/Select';
import Button from '../components/form/Button';
import SeeMore from '../components/SeeMore';
import { getProductsRequest } from '../store/actions/products';
import ProductsTable from '../components/ProductsTable';
import { forceVisible } from 'react-lazyload';

const options = [
  { label: 'All', value: 'null' },
  { label: 'Active', value: 'active' },
  { label: 'Deactivated', value: 'disabled' },
];

function Products() {
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState({});
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  const productsTotalPages = useSelector((state) => state.products.productsTotalPages);

  const handleSeeMore = useCallback(() => {
    dispatch(getProductsRequest({ ...searchData, page: page + 1 }));
    setPage(page + 1);
  }, [page]);

  const handleChange = useCallback((key, value) => {
    searchData[key] = value;
    setSearchData({ ...searchData });
  }, [searchData]);

  const handleSearch = useCallback(async () => {
    setLoading(true);
    await dispatch(getProductsRequest({ page, ...searchData }, false));
    forceVisible();
    setLoading(false);
  }, [searchData]);

  return (
    <Wrapper>
      <div id="products">
        <div className="top">
          <div>
            <Input
              label="Search"
              onChange={(ev) => {
                handleChange('sName', ev.target.value);
              }}
              value={searchData.sName}
            />
            <Input
              label="Search By Item"
              onChange={(ev) => {
                handleChange('sItemNumber', ev.target.value);
              }}
              value={searchData.sItemNumber}
            />
            <Select
              options={options}
              placeholder="Status"
              onChange={(val) => {
                handleChange('status', val.value);
              }}
              value={options.find((o) => searchData.status === o.value)}
            />
            <Button
              title="SEARCH"
              onClick={handleSearch}
              loading={loading}
            />
          </div>
        </div>
        <ProductsTable />
        {+productsTotalPages > 1 && +productsTotalPages > page ? <SeeMore onClick={handleSeeMore} /> : null}
      </div>

    </Wrapper>
  );
}

export default Products;
