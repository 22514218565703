import _ from 'lodash';
import {
  GET_PRODUCTS,
} from '../actions/products';

const initialState = {
  products: [],
  productsStatus: '',
  productsTotalPages: 1,
};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_PRODUCTS.REQUEST: {
      return {
        ...state,
        productsStatus: 'request',
      };
    }
    case GET_PRODUCTS.SUCCESS: {
      let { data: { products } } = action.payload;
      const { data: { totalPages }, cache } = action.payload;
      if (cache) {
        products = _.uniqBy([...state.products, ...products], 'id');
      }
      return {
        ...state,
        products,
        productsStatus: 'ok',
        productsTotalPages: totalPages,
      };
    }
    case GET_PRODUCTS.FAIL: {
      return {
        ...state,
        products: [],
        productsStatus: 'fail',
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
