import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Input from '../components/form/Input';
import Button from '../components/form/Button';
import { ReactComponent as EyeIconSvg } from '../assets/icons/eye-vector.svg';
import { ReactComponent as LockIconSvg } from '../assets/icons/lock.svg';
import Checkbox from '../components/form/Checkbox';
import { getProfileRequest, loginRequest } from '../store/actions/account';

function Login() {
  const [medicalId, setMedicalId] = useState('');
  const [errors, setErrors] = useState({});
  const [password, setPassword] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const [remember, setRemember] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.account);

  useEffect(() => {
    if (token) {
      navigate('/');
    }
  }, [token]);

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const { payload: { data } } = await dispatch(loginRequest(medicalId, password, remember));
    setLoading(false);
    if (data.status === 'error') {
      if (_.isEmpty(data.errors)) {
        toast.error(_.upperFirst(data.message));
      } else {
        setErrors(data.errors);
      }
    } else {
      dispatch(getProfileRequest());
    }
  };

  if (token) {
    return null;
  }

  return (
    <div className="layoutLogOut">
      <Helmet>
        <body className="logout" />
      </Helmet>
      <div className="log-in">
        <div className="login-form">
          <div className="title-block">
            <h2>Log In</h2>
          </div>
          <form className="form-input-container" onSubmit={handleSubmit}>
            <div className="input-block">
              <div className="input-container">
                <span>Email*</span>
                <Input
                  value={medicalId}
                  onChange={(ev) => {
                    setMedicalId(ev.target.value);
                    setErrors({});
                  }}
                  error={errors.medicalId ? 'Please enter a medical id.' : ''}
                />
              </div>
              <div className="input-container">
                <div className="password-text">
                  <span>Password*</span>
                  <span>Forgot Password?</span>
                </div>
                <Input
                  className="password"
                  leftIcon={<LockIconSvg className="icon left" />}
                  rightIcon={(
                    <EyeIconSvg
                      className={`icon right ${passwordType === 'password' ? 'password' : ''}`}
                      onClick={() => {
                        if (passwordType === 'text') {
                          setPasswordType('password');
                        } else {
                          setPasswordType('text');
                        }
                      }}
                    />
                  )}
                  type={passwordType}
                  value={password}
                  onChange={(ev) => {
                    setPassword(ev.target.value);
                  }}
                />
                <div className="input-checkbox-block">
                  <Checkbox
                    label="Remember me"
                    onChange={() => {
                      setRemember(!remember);
                    }}
                    checked={remember}
                  />
                  <span>Forgot Password?</span>
                </div>
                <Button title="LOG IN" className="btn-teal-big btn-log-in" loading={loading} type="submit" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
