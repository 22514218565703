import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Wrapper from '../components/Wrapper';
import OrdersTable from '../components/OrdersTable';
import Input from '../components/form/Input';
import Datepicker from '../components/form/Datepicker';
import Button from '../components/form/Button';
import Select from '../components/form/Select';
import SeeMore from '../components/SeeMore';
import { getOrdersRequest, usersAutocompleteRequest } from '../store/actions/orders';

const options = [
  { label: 'Shipped', value: 'shipped' },
  { label: 'Done', value: 'done' },
  { label: 'Pending', value: 'pending' },
  { label: 'Canceled', value: 'canceled' },
];

function Orders() {
  const ordersTotalPages = useSelector((state) => state.orders.ordersTotalPages);
  const usersAutocomplete = useSelector((state) => state.orders.usersAutocomplete);
  const [page, setPage] = useState(1);
  const [searchData, setSearchData] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleChange = useCallback((key, value) => {
    searchData[key] = value;
    setSearchData({ ...searchData });
  }, [searchData]);

  const handleSearch = useCallback(async () => {
    setLoading(true);
    await dispatch(getOrdersRequest({ page, ...searchData }, false));
    setLoading(false);
  }, [searchData]);

  const handleSeeMore = useCallback(() => {
    dispatch(getOrdersRequest({ ...searchData, page: page + 1 }, true));
    setPage(page + 1);
  }, [page]);

  const handleLoadOptions = useCallback(async (value) => {
    const { payload: { data } } = await dispatch(usersAutocompleteRequest(value));
    return data.list || [];
  }, []);

  return (
    <Wrapper>
      <div id="orders">
        <div className="top">
          <div>
            <Select
              loadOptions={handleLoadOptions}
              label="Search By Patient name"
              isAsync
              defaultOptions
              getOptionLabel={(o) => `${o.fName} ${o.lName}`}
              getOptionValue={(o) => o.id}
              onChange={(val) => handleChange('customerId', val.id)}
              value={usersAutocomplete.find((u) => u.id === searchData.customerId)}
            />
            <Datepicker
              label="Search By Date"
              onChange={(val) => handleChange('day', moment(val).format('YYYY-MM-DD'))}
              value={searchData.day ? moment(searchData.day, 'YYYY-MM-DD').toDate() : undefined}
              showYearDropdown
              yearDropdownItemNumber={70}
            />
            <Input
              label="Search By Number"
              placeholder="200001"
              onChange={(ev) => handleChange('sItemNumber', ev.target.value)}
              value={searchData.sItemNumber}
            />
            <Input
              label="Search By Product Name"
              placeholder="Product Name"
              onChange={(ev) => handleChange('sProductName', ev.target.value)}
              value={searchData.sProductName}
            />
            <Select
              options={options}
              label="Search By Status"
              isSearchable={false}
              onChange={(val) => {
                handleChange('status', val.value);
              }}
              value={options.find((o) => searchData.status === o.value)}
            />
            <Button
              title="SEARCH"
              onClick={handleSearch}
              loading={loading}
            />
          </div>
        </div>
        <OrdersTable />
        {+ordersTotalPages > 1 && +ordersTotalPages > page ? <SeeMore onClick={handleSeeMore} /> : null}
      </div>
    </Wrapper>
  );
}

export default Orders;
