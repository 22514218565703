import axios from 'axios';
import Account from './helpers/Account';

const { REACT_APP_API_URL } = process.env;

const api = axios.create({
  baseURL: REACT_APP_API_URL,
  'content-type': 'application/json',
});

api.interceptors.request.use((config) => {
  const token = Account.getToken('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => Promise.reject(error));

api.interceptors.response.use((res) => res, (error) => {
  if (error?.response?.status === 401) {
    Account.delete();
    window.location.href = '/';
  }
  return Promise.reject(error);
});

const apiTokens = {};

class Api {
  static cancel(methodName) {
    if (apiTokens[methodName]) {
      apiTokens[methodName]();
    }
  }

  static cancelToken(methodName) {
    return new axios.CancelToken((token) => {
      apiTokens[methodName] = token;
    });
  }

  static login(medicalId, password) {
    return api.post('/users/login/health-plan/admins', { medicalId, password });
  }

  static getHealthplans(params) {
    return api.get('/health-plans', { params });
  }

  static getPatientInfo(id) {
    return api.get('/users/profile', { params: { id } });
  }

  static getPatients(params) {
    this.cancel('getPatients');
    return api.get('/users/patients', { params, cancelToken: this.cancelToken('getPatients') });
  }

  static getProfile() {
    return api.get('/users/profile');
  }

  static getProducts(data) {
    return api.post('/products/get/list', data);
  }

  static getNotifications(params) {
    return api.get('/notifications', { params });
  }

  static getUnreadNotificationsCount() {
    return api.get('/notifications/unread/count');
  }

  static markNotificationsAsRead() {
    return api.post('/notifications/mark/read');
  }

  static getOrdersCategories(data) {
    return api.get('/orders/categories/top', { params: data });
  }

  static getOrdersSales(data) {
    return api.get('/orders/sales/top', { params: data });
  }

  static getOrdersMembers(data) {
    return api.get('/orders/top/members', { params: data });
  }

  static getOrdersProductsHealthPlan(data) {
    return api.get('/orders/top/products/health-plan-based', { params: data });
  }

  static getUsersStatisticPatients(data) {
    return api.get('/users/statistic/patients', { params: data });
  }

  static getOrdersStatisticSales(data) {
    return api.get('/orders/statistic/sales', { params: data });
  }

  static getOPaymentsStatisticFunds(data) {
    return api.get('/payments/statistics/funds', { params: data });
  }

  static getBenefitSales(data) {
    return api.get('/orders/benefit/sales', { params: data });
  }

  static getOrders(params) {
    return api.get('/orders', { params });
  }
  static usersAutocomplete(sName) {
    return api.get('/users/autocomplete', { params: { sName } });
  }
}

export default Api;
