import React, { Component } from 'react';
import {
  BrowserRouter, Routes, Route, Navigate,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Dashboard from './pages/Dashboard';
import Orders from './pages/Orders';
import Settings from './pages/Settings';
import Patients from './pages/Patients';
import PatientSingle from './pages/PatientSingle';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Products from './pages/Products';

class App extends Component {
  render() {
    return (
      <>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />

            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/products" element={<Products />} />
            <Route path="/settings" element={<Navigate to="/settings/account" />} />
            <Route path="/settings/:tab" element={<Settings />} />
            <Route path="/patients" element={<Patients />} />
            <Route path="/patients/:patientId" element={<PatientSingle />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer />

      </>

    );
  }
}

export default App;
